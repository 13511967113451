import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'; // Импортируем Link для маршрутизации
import './ProductList.css'; // Стили для компонента
import img1 from '../img/0e823d58-aea7-45d4-95a3-ee5632f7a0da.webp';
import img2 from '../img/2669b555-dea6-413a-83c5-58051dbc6d71.webp';
import img3 from '../img/958494e0-003f-43af-bae9-d95c3997ee69.webp';
import img4 from '../img/a9c95def-703d-4565-b578-424dc7f083d9.webp';


const products = [
  {
    id: 1,
    name: 'Gafas para protección ocular',
    description: 'Protege tus ojos mientras trabajas frente a la pantalla.',
    price: '$50',
    image: img1, // Замените ссылку на реальное изображение
  },
  {
    id: 2,
    name: 'Suplemento para la visión',
    description: 'Mejora tu salud visual con nuestros suplementos.',
    price: '$30',
    image: img2,
  },
  {
    id: 3,
    name: 'Kit de ejercicios oculares',
    description: 'Una serie de ejercicios para fortalecer los músculos oculares.',
    price: '$70',
    image: img3,
  },
  {
    id: 4,
    name: 'Guía de Gimnasia Ocular',
    description: 'Aprende cómo cuidar tus ojos con nuestra guía completa.',
    price: '$20',
    image: img4,
  },
];

const ProductList = () => {
  return (
    <div className="product-list-container">
      <Row gutter={[16, 16]}>
        {products.map(product => (
          <Col key={product.id} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              cover={<img alt={product.name} src={product.image} />}
              actions={[
                <Link to={`/product/${product.id}`}>
                  <Button type="primary">Comprar</Button>
                </Link>,
              ]}
            >
              <Card.Meta 
                title={product.name} 
                description={product.description} 
              />
              <div className="product-price">
                <strong>{product.price}</strong>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductList;
