import React from 'react';
import { Typography, Row, Col, Card, Button, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons'; // Иконка для кнопки
import mainImage from '../img/2669b555-dea6-413a-83c5-58051dbc6d71.webp'; // Предположим, что картинка находится в папке img
import './Home.css';
import ProductList from './ProductList';
import { Link } from 'react-router-dom'; // Импортируем Link из react-router-dom

const { Title, Paragraph } = Typography;

const Home = () => {
  return (
    <div className="home-container">
      {/* Главное изображение и текст поверх изображения */}
      <div className="hero-section">
        <img src={mainImage} alt="Ejercicio Ocular" className="main-image" />
        <div className="hero-text">
          <Title level={1} className="hero-title">Bienvenidos al Curso de Gimnasia Ocular</Title>
          <Paragraph className="hero-description">
            Este curso ofrece una variedad de ejercicios que pueden ayudar a mejorar la salud de tus ojos y aumentar la comodidad, especialmente si pasas mucho tiempo frente a pantallas. Aprende técnicas que te ayudarán a mejorar el enfoque y reducir la fatiga ocular.
          </Paragraph>
          <Button type="primary" size="large" className="cta-button">
          <Link to="/market" style={{ color: 'white' }}>
   SHOP <ArrowRightOutlined />
  </Link>
          </Button>
        </div>
      </div>

      {/* Bloques principales del curso */}
      <div className="course-info">
        <Row gutter={[24, 24]} justify="center">
          {/* Блок 1 */}
          <Col xs={24} md={12} lg={8}>
            <Card title="Beneficios para la salud ocular" hoverable>
              <Paragraph>
                En este curso, aprenderás cómo fortalecer los músculos oculares, mejorar el enfoque y mantener una buena salud visual. Los ejercicios pueden ayudar a aliviar la tensión causada por el uso prolongado de pantallas.
              </Paragraph>
            </Card>
          </Col>
          <ProductList></ProductList>
          {/* Блок 2 */}
          <Col xs={24} md={12} lg={8}>
            <Card title="Consejos para el cuidado de los ojos" hoverable>
              <Paragraph>
                Una buena alimentación y un descanso ocular regular son factores clave para mantener la salud de los ojos. También te contaremos sobre la importancia de una dieta equilibrada y cómo reducir la fatiga ocular.
              </Paragraph>
            </Card>
          </Col>
          {/* Блок 3 */}
          <Col xs={24} md={12} lg={8}>
            <Card title="Ejercicios para los ojos" hoverable>
              <Paragraph>
                Ofrecemos instrucciones paso a paso para realizar ejercicios que te ayudarán a mejorar la visión y reducir la tensión ocular. Estos ejercicios se pueden realizar en cualquier momento y lugar.
              </Paragraph>
            </Card>
          </Col>
          {/* Блок 4 */}
          <Col xs={24} md={12} lg={8}>
            <Card title="Certificación internacional" hoverable>
              <Paragraph>
                Al finalizar el curso y aprobar el examen, recibirás un certificado internacional que valida tus conocimientos y habilidades en gimnasia ocular, lo que será una excelente adición a un estilo de vida saludable.
              </Paragraph>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Призыв к действию */}
      <div className="call-to-action">
        <Space direction="vertical" align="center" size="large">
          <Title level={2}>¿Listo para mejorar tu visión?</Title>
          <Button type="primary" size="large">
  <Link to="/market" style={{ color: 'white' }}>
   SHOP <ArrowRightOutlined />
  </Link>
</Button>
        </Space>
      </div>
    </div>
  );
};

export default Home;
