import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import './Contacto.css'; // Подключаем файл стилей

const TELEGRAM_BOT_TOKEN = '8191992355:AAGaaFZh07BmEeJzOrjKTzFXAqLwjzGfL9c';
const TELEGRAM_CHAT_ID = '-4540973525';

const Contacto = () => {
  const [checked, setChecked] = useState(false); // Состояние для чекбокса согласия

  const onFinish = async (values) => {
    if (!checked) {
      notification.error({
        message: 'Error',
        description: 'Debes aceptar la política de privacidad para continuar.',
      });
      return;
    }

    const message = `
      <b>White_2</b>
      <b>Nombre:</b> ${values.name}
      <b>Email:</b> ${values.email}
      <b>Mensaje:</b> ${values.message}
    `;

    try {
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: 'HTML',
        }),
      });

      if (response.ok) {
        notification.success({
          message: 'Mensaje enviado',
          description: 'Tu mensaje ha sido enviado con éxito. Nos pondremos en contacto contigo pronto.',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'No se pudo enviar el mensaje. Inténtalo de nuevo más tarde.',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      notification.error({
        message: 'Error',
        description: 'No se pudo enviar el mensaje. Inténtalo de nuevo más tarde.',
      });
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Formulario de Contacto</h2>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item 
          label="Nombre" 
          name="name" 
          rules={[{ required: true, message: 'Por favor, introduce tu nombre.' }]}>
          <Input placeholder="Introduce tu nombre" />
        </Form.Item>

        <Form.Item 
          label="Correo electrónico" 
          name="email" 
          rules={[{ required: true, type: 'email', message: 'Por favor, introduce un correo electrónico válido.' }]}>
          <Input placeholder="Introduce tu correo electrónico" />
        </Form.Item>

        <Form.Item 
          label="Mensaje" 
          name="message" 
          rules={[{ required: true, message: 'Por favor, introduce tu mensaje.' }]}>
          <Input.TextArea rows={4} placeholder="Escribe tu mensaje" />
        </Form.Item>

        <Form.Item>
          <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            Acepto la <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">política de privacidad</a>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar Mensaje
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Contacto;
