import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const AboutUs = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Title level={1}>Sobre Nosotros</Title>
      <Paragraph>
        En <strong>Ocular Exercise</strong>, somos un equipo apasionado por la salud visual y el bienestar ocular. Nuestra misión es ofrecer herramientas y recursos prácticos para que las personas puedan cuidar de sus ojos de manera proactiva. 
        Creemos que la visión saludable es clave para una vida plena y productiva, especialmente en un mundo donde el uso de pantallas es inevitable.
      </Paragraph>

      <Title level={2}>Nuestra Historia</Title>
      <Paragraph>
        Fundada por profesionales de la salud visual, <strong>Ocular Exercise</strong> nació con el objetivo de ayudar a las personas a enfrentar los desafíos que el uso constante de dispositivos electrónicos presenta para la visión. Con el tiempo, nuestro equipo ha crecido para incluir expertos en oftalmología, optometría y profesionales en el campo del bienestar visual.
      </Paragraph>

      <Title level={2}>Nuestro Enfoque</Title>
      <Paragraph>
        Creemos en la educación preventiva y en la creación de hábitos saludables para proteger la visión a largo plazo. Nuestro curso de Gimnasia Ocular está diseñado para enseñar a los usuarios ejercicios simples pero efectivos que pueden mejorar el enfoque y reducir la fatiga ocular. 
        Cada ejercicio está respaldado por investigaciones científicas y ha sido probado por profesionales de la salud visual.
      </Paragraph>

      <Title level={2}>Nuestros Valores</Title>
      <Paragraph>
        <ul>
          <li><strong>Compromiso con la salud visual:</strong> Cada producto y servicio que ofrecemos está diseñado con el objetivo de mejorar y mantener la visión de nuestros usuarios.</li>
          <li><strong>Innovación continua:</strong> Trabajamos constantemente para integrar las últimas investigaciones y tecnologías en nuestras soluciones.</li>
          <li><strong>Educación y apoyo:</strong> Nos dedicamos a educar a nuestros usuarios y brindarles el apoyo necesario para que puedan adoptar prácticas de cuidado ocular saludables en su día a día.</li>
          <li><strong>Accesibilidad:</strong> Creemos que la salud visual es un derecho fundamental, por lo que trabajamos para hacer nuestros recursos accesibles a todas las personas, independientemente de su ubicación o nivel económico.</li>
        </ul>
      </Paragraph>

      <Title level={2}>Nuestro Equipo</Title>
      <Paragraph>
        Nuestro equipo está compuesto por expertos en el cuidado de la visión y la salud en general. Trabajamos juntos para asegurarnos de que cada cliente reciba información clara y precisa, además de un plan de acción adecuado para mantener su salud ocular.
      </Paragraph>

      <Title level={2}>Contáctanos</Title>
      <Paragraph>
        Si tienes alguna pregunta o deseas obtener más información sobre nuestro curso o cualquier otro servicio que ofrecemos, no dudes en <a href="/contact">ponerte en contacto con nosotros</a>. Estamos aquí para ayudarte a cuidar de tus ojos.
      </Paragraph>
    </div>
  );
}

export default AboutUs;
