import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Title level={1}>Términos y Condiciones</Title>
      <Paragraph>
        Estos términos y condiciones rigen el uso de nuestro sitio web. Al acceder a este sitio, aceptas cumplir con estos términos.
      </Paragraph>
      <Title level={2}>Uso del Sitio</Title>
      <Paragraph>
        No puedes usar nuestro sitio web para actividades ilícitas o para infringir los derechos de terceros.
      </Paragraph>
      <Title level={2}>Propiedad Intelectual</Title>
      <Paragraph>
        Todo el contenido en este sitio, incluidos textos, imágenes y gráficos, es propiedad de Ocular Exercise y está protegido por derechos de autor.
      </Paragraph>
      <Title level={2}>Limitación de Responsabilidad</Title>
      <Paragraph>
        No seremos responsables de ningún daño derivado del uso de nuestro sitio web o la incapacidad de utilizarlo.
      </Paragraph>
      <Title level={2}>Cambios en los Términos</Title>
      <Paragraph>
        Nos reservamos el derecho de actualizar estos términos en cualquier momento. Se recomienda revisar esta página periódicamente.
      </Paragraph>
    </div>
  );
};

export default TermsOfService;
