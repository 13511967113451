import React, { useState } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import './Header.css';

const { Header } = Layout;

const CustomHeader = () => {
  const [visible, setVisible] = useState(false); // Состояние для управления Drawer

  // Открытие Drawer
  const showDrawer = () => {
    setVisible(true);
  };

  // Закрытие Drawer
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Header className="custom-header">
      <div className="logo">
        <Link to="/"> <h2>Ocular Exercise</h2></Link>
       
      </div>

      {/* Основное меню для ПК */}
      <Menu mode="horizontal" className="custom-menu" theme="light">
        <Menu.Item key="1">
          <Link to="/">Inicio</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/about">Acerca de Nosotros</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/contact">Contacto</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/market">SHOP</Link>
        </Menu.Item>
      </Menu>

      {/* Иконка меню для мобильной версии */}
      <MenuOutlined className="mobile-menu-icon" onClick={showDrawer} />

      {/* Drawer для мобильного меню */}
      <Drawer
        title="Menú"
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={true}
      >
        <Menu mode="vertical" theme="light">
        <Menu.Item key="1">
          <Link to="/">Inicio</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/about">Acerca de Nosotros</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/contact">Contacto</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/market">SHOP</Link>
        </Menu.Item>
        </Menu>
      </Drawer>
    </Header>
  );
};

export default CustomHeader;
