import React from 'react';
import { Layout } from 'antd';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Policy from './pages/Policy';
import Product from './pages/Product';
import PrivacyPolicy from './pages/PrivacyPolicy';  // Импорт страницы Политики конфиденциальности
import TermsOfService from './pages/TermsOfService'; // Импорт страницы Условий использования
import RefundPolicy from './pages/RefundPolicy';     // Импорт страницы Политики возврата
import Header from './components/Header';            // Подключаем созданный Header
import CustomFooter from './components/Footer';      // Подключение футера
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail'; 


const { Content } = Layout;

function App() {
  return (
    <Router>
      <Layout>
        {/* Подключаем наш компонент Header */}
        <Header />
        
        {/* Основной контент */}
        <Content style={{ padding: '0 50px', marginTop: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/product" element={<Product />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />    {/* Страница Политики конфиденциальности */}
            <Route path="/terms-of-service" element={<TermsOfService />} />  {/* Страница Условий использования */}
            <Route path="/refund-policy" element={<RefundPolicy />} />  
            <Route path="market" element={<ProductList></ProductList>} />   {/* Страница Политики возврата */}
            <Route path="/product/:id" element={<ProductDetail />} />
          </Routes>
        </Content>

        {/* Футер */}
        <CustomFooter />
      </Layout>
    </Router>
  );
}

export default App;
