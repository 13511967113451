import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Typography, Form, Input, Checkbox, notification } from 'antd';
import img1 from '../img/0e823d58-aea7-45d4-95a3-ee5632f7a0da.webp';
import img2 from '../img/2669b555-dea6-413a-83c5-58051dbc6d71.webp';
import img3 from '../img/958494e0-003f-43af-bae9-d95c3997ee69.webp';
import img4 from '../img/a9c95def-703d-4565-b578-424dc7f083d9.webp';
import './ProductDetail.css'; // Подключаем стили

const { Title, Paragraph } = Typography;

const TELEGRAM_BOT_TOKEN = '8191992355:AAGaaFZh07BmEeJzOrjKTzFXAqLwjzGfL9c';
const TELEGRAM_CHAT_ID = '-4540973525';

const products = [
  {
    id: 1,
    name: 'Gafas para protección ocular',
    description: 'Protege tus ojos mientras trabajas frente a la pantalla.',
    price: '$50',
    image: img1,
    details: 'Estas gafas están diseñadas para bloquear la luz azul y reducir la fatiga ocular.',
  },
  {
    id: 2,
    name: 'Suplemento para la visión',
    description: 'Mejora tu salud visual con nuestros suplementos.',
    price: '$30',
    image: img2,
    details: 'Nuestro suplemento contiene vitaminas y antioxidantes que ayudan a mantener la visión saludable.',
  },
  {
    id: 3,
    name: 'Kit de ejercicios oculares',
    description: 'Una serie de ejercicios para fortalecer los músculos oculares.',
    price: '$70',
    image: img3,
    details: 'Este kit incluye herramientas y guías paso a paso para mejorar tu enfoque y visión.',
  },
  {
    id: 4,
    name: 'Guía de Gimnasia Ocular',
    description: 'Aprende cómo cuidar tus ojos con nuestra guía completa.',
    price: '$20',
    image: img4,
    details: 'La guía ofrece ejercicios y consejos prácticos para mantener la salud ocular en buen estado.',
  },
];

const ProductDetail = () => {
  const { id } = useParams();
  const product = products.find((p) => p.id === parseInt(id));
  const [checked, setChecked] = useState(false);

  const sendToTelegram = async (message) => {
    try {
      await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: 'HTML',
        }),
      });
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
    }
  };

  const onFinish = (values) => {
    if (!checked) {
      notification.error({
        message: 'Error',
        description: 'Debes aceptar los términos y condiciones para continuar.',
      });
      return;
    }

    const message = `
      <b>Nueva Orden:</b>
      <b>Producto:</b> ${product.name}
      <b>Precio:</b> ${product.price}
      <b>Nombre Completo:</b> ${values.fullName}
      <b>Correo Electrónico:</b> ${values.email}
      <b>Dirección de Envío:</b> ${values.address}
    `;

    sendToTelegram(message);

    notification.success({
      message: 'Orden completada',
      description: `Gracias por tu compra de ${product.name}. Pronto nos pondremos en contacto.`,
    });

    console.log('Valores enviados:', values);
  };

  if (!product) {
    return <div>Producto no encontrado</div>;
  }

  return (
    <div className="product-detail-container">
      <div className="product-detail-content">
        <div className="product-image">
          <img alt={product.name} src={product.image} />
        </div>
        <div className="product-info">
          <Card
            actions={[
              
            ]}
          >
            <Title level={2}>{product.name}</Title>
            <Paragraph>{product.description}</Paragraph>
            <Paragraph>{product.details}</Paragraph>
          </Card>

          <div className="order-form-container">
            <Title level={3}>Realiza tu pedido</Title>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Nombre Completo"
                name="fullName"
                rules={[{ required: true, message: 'Por favor, introduce tu nombre completo.' }]}
              >
                <Input placeholder="Ingresa tu nombre completo" />
              </Form.Item>

              <Form.Item
                label="Correo Electrónico"
                name="email"
                rules={[
                  { required: true, message: 'Por favor, introduce tu correo electrónico.' },
                  { type: 'email', message: 'Por favor, introduce un correo electrónico válido.' },
                ]}
              >
                <Input placeholder="Ingresa tu correo electrónico" />
              </Form.Item>

              <Form.Item
                label="Dirección de Envío"
                name="address"
                rules={[{ required: true, message: 'Por favor, introduce tu dirección de envío.' }]}
              >
                <Input placeholder="Ingresa tu dirección" />
              </Form.Item>

              <Form.Item>
                <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
                  Acepto los <a href="/terms" target="_blank" rel="noopener noreferrer">términos y condiciones</a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Realizar Pedido
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
