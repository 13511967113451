import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import './Footer.css'; // Подключаем стили для футера

const { Footer } = Layout;
const { Text } = Typography;

const CustomFooter = () => {
  return (
    <Footer className="custom-footer">
      <div className="footer-container">
        <Row gutter={[16, 16]}>
          {/* Колонка с основными ссылками */}
          <Col xs={24} sm={12} md={6}>
            <h3>Información</h3>
            <ul className="footer-links">
              <li><Link to="/privacy-policy">Política de Privacidad</Link></li>
              <li><Link to="/terms-of-service">Términos y Condiciones</Link></li>
              <li><Link to="/refund-policy">Política de Reembolso</Link></li>
            </ul>
          </Col>

          {/* Колонка с контактами */}
          <Col xs={24} sm={12} md={6}>
            <h3>Contacto</h3>
            <ul className="footer-links">
              <li><Link to="/contact">Formulario de Contacto</Link></li>
              <li>Email: contacto@ocular-exercise.com</li>
              <li>Teléfono: +34 123 456 789</li>
            </ul>
          </Col>

          {/* Колонка с социальными сетями */}
          <Col xs={24} sm={12} md={6}>
            <h3>Síguenos</h3>
            <ul className="footer-links">
              <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            </ul>
          </Col>

          {/* Колонка с копирайтом */}
          <Col xs={24} sm={12} md={6}>
            <h3>Ocular Exercise</h3>
            <Text type="secondary">© 2024 Ocular Exercise. Todos los derechos reservados.</Text>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default CustomFooter;
