import React from 'react';
import { Card, Button, Form, InputNumber } from 'antd';

const Product = () => {
  const onFinish = (values) => {
    console.log('Order submitted:', values);
  };

  return (
    <div>
      <Card title="Ocular Exercise Course" bordered={false}>
        <p>This course provides exercises to enhance eye comfort and reduce strain.</p>
        <p>Price: $49.99</p>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: 'Please select a quantity!' }]}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Order Now</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default Product;
