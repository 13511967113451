import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const RefundPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Title level={1}>Política de Reembolso</Title>
      <Paragraph>
        En Ocular Exercise, ofrecemos una garantía de devolución del dinero de 30 días. Si no estás satisfecho con nuestro curso, puedes solicitar un reembolso dentro de los 30 días posteriores a la compra.
      </Paragraph>
      <Title level={2}>Proceso de Reembolso</Title>
      <Paragraph>
        Para solicitar un reembolso, contáctanos a través de nuestro formulario de contacto con tu número de pedido y la razón del reembolso.
      </Paragraph>
      <Title level={2}>Exclusiones</Title>
      <Paragraph>
        No se otorgarán reembolsos después de 30 días de la compra o si has completado más del 50% del curso.
      </Paragraph>
    </div>
  );
};

export default RefundPolicy;
