import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const Policy = () => {
  return (
    <div>
      <Title>Privacy Policy</Title>
      <Paragraph>
        We value your privacy and are committed to protecting your personal data. This policy outlines how we collect, use, and safeguard your information.
      </Paragraph>
    </div>
  );
}

export default Policy;
