import React from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Title level={1}>Política de Privacidad</Title>
      <Paragraph>
        En Ocular Exercise, respetamos su privacidad y nos comprometemos a proteger la información personal que compartes con nosotros.
      </Paragraph>
      <Title level={2}>Recopilación de Información</Title>
      <Paragraph>
        Recopilamos información como nombre, correo electrónico, y otros datos personales cuando te registras en nuestro sitio web o te inscribes en el curso.
      </Paragraph>
      <Title level={2}>Uso de la Información</Title>
      <Paragraph>
        La información que recopilamos se utiliza para mejorar nuestro sitio web, enviar actualizaciones sobre los cursos, y personalizar tu experiencia.
      </Paragraph>
      <Title level={2}>Cookies</Title>
      <Paragraph>
        Utilizamos cookies para rastrear tus interacciones con nuestro sitio y mejorar tu experiencia.
      </Paragraph>
      <Title level={2}>Protección de la Información</Title>
      <Paragraph>
        Implementamos medidas de seguridad para proteger tu información personal. No vendemos, intercambiamos ni transferimos tu información a terceros sin tu consentimiento.
      </Paragraph>
      <Title level={2}>Contacto</Title>
      <Paragraph>
        Si tienes alguna pregunta sobre esta política de privacidad, puedes contactarnos a través de nuestro formulario de contacto.
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
